import React from "react";

interface ConfirmViewProps {
    setShowConfirm: Function;
}

function ConfirmView({ setShowConfirm } : ConfirmViewProps) {
    return (
        <div className="confirmView" >
            <div className="confirmViewBody" >
                <div className="confirmViewMessage" >
                    <h1>Votre demande d'ouverture est validée, vous avez 5 minutes pour ouvrir l'armoire.</h1>
                </div>
            </div>
            <div className="confirmViewBackButton" >
                <button
                        type="button"
                        onClick={() => setShowConfirm(false)}>Retour</button>
            </div>
        </div>
    )
}

export default ConfirmView;
