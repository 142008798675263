import React, {useState} from 'react';
import WorkView from "./WorkView";
import ConfirmView from "./ConfirmView";

interface CabinetOpeningMenuProps {
    token: string | null;
}

function CabinetOpeningMenu({token} : CabinetOpeningMenuProps) {
    const [method, setMethod] = useState<string>('');
    const [cabinetName, setCabinetName] = useState<string | null>('');
    const [imeiNumber, setImeiNumber] = useState<string | null>('');
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    
    const onClickChosen = (e: any) => {
        if (e.target.id === "methodByQrCode") {
            setMethod('OpenCabinetWithQrCode');
        } else if (e.target.id === "methodByName") {
            setMethod('OpenCabinetWithName');
        }
    }

    return (
        <>
            {
                !method?.length ?
                    <div className="flex">
                        <div className="mx-1">
                            <button className="primary-button"
                                    id="methodByQrCode"
                                    onClick={(e) => onClickChosen(e)}
                            >
                                Scanner le QR Code
                            </button>
                        </div>
                        <div className="mx-1">
                            <button className="primary-button"
                                    id="methodByName"
                                    onClick={(e) => onClickChosen(e)} >
                                Saisir le nom de l'armoire
                            </button>
                        </div>
                    </div>
                :
                    showConfirm ?
                        <ConfirmView setShowConfirm={setShowConfirm} />
                    :
                        <WorkView token={token}
                                  setShowConfirm={setShowConfirm}
                                  setCabinetName={setCabinetName}
                                  cabinetName={cabinetName}
                                  imeiNumber={imeiNumber}
                                  setImeiNumber={setImeiNumber}
                                  method={method}
                                  setMethod={setMethod}
                        />
            }
        </>
    )
}

export default CabinetOpeningMenu;
