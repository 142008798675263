import React, {useState} from 'react';
// @ts-ignore
import Logo from '../../assets/images/logo.png';
import {ToastContainer} from "react-toastify";
import CabinetOpeningMenu from './cabinet-opening/CabinetOpeningMenu';
import Footer from './Footer';
import {useAsyncEffect} from "../hooks/useAsyncEffect";
import {TechnicianRepository} from "../services/backend";

function App() {
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
    const [urlToken, setUrlToken] = useState<string | null>(localStorage.getItem('url_token'));

    useAsyncEffect(async () => {
        const hashFragment = window.location.hash;
        const jwt = new URLSearchParams(hashFragment.slice(1)).get('token');
        if (jwt && jwt !== urlToken) {
            const authToken = await new TechnicianRepository().getIdToken(jwt);
            if (authToken) {
                // @ts-ignore
                setToken(authToken.value);
                // @ts-ignore
                localStorage.setItem('token', authToken.value);
                localStorage.setItem('url_token', jwt);
            }
        }
    }, []);

    return (
        <div className="App container">
            <header className="App-header">
                <ToastContainer autoClose={2500}
                                limit={1} />
            </header>
            <div className="App-body">
                <div className="App-Logo">
                    <img src={Logo} alt="idips-logo" />
                </div>
                <CabinetOpeningMenu token={token} />
            </div>
            <div className="App-footer">
                <Footer />
            </div>
        </div>
    );
}

export default App;
