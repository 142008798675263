import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import {QrReader} from 'react-qr-reader';
import { CabinetRepository,
         OpenCabinetWithNameRequest,
         OpenCabinetWithQrCodeRequest } from "../../services/backend";

interface WorkViewProps {
    token: string | null;
    imeiNumber: string | null;
    cabinetName: string | null;
    method: string | null;
    setShowConfirm: Function;
    setCabinetName: Function;
    setImeiNumber: Function;
    setMethod: Function;
}

function WorkView({ token,
                    setShowConfirm,
                    setCabinetName,
                    cabinetName,
                    imeiNumber,
                    setImeiNumber,
                    method,
                    setMethod } : WorkViewProps) {
    const [scannerView, setScannerView] = useState<boolean>(false);
    const [scanResult, setScanResult] = useState<boolean>(false);

    const onSubmitCabinetOpening = async (e: { preventDefault: () => void; }) => {
        if (e) e.preventDefault();
        let response;
        try {
            if (method === "OpenCabinetWithName") {
                response = await new CabinetRepository().openCabinetWithName({
                    token: token, cabinetName: cabinetName } as OpenCabinetWithNameRequest);
            } else {
                response = await new CabinetRepository().openCabinetWithQrCode({
                    token: token, imeiNumber: imeiNumber } as OpenCabinetWithQrCodeRequest);
            }
            if (response?.status === 200) {
                setShowConfirm(true);
            } else {
                toast.error("L'armoire n'a pas été ouverte. Veuillez contacter votre opérateur");
                setScanResult(false);
            }
        } catch (err) {
            toast.error("L'armoire n'a pas été ouverte. Veuillez contacter votre opérateur");
            setScanResult(false);
            console.log(err);
        }
    }

    const onClickSwitchScanner = (e: any) => {
        setScannerView(!scannerView);
        if (e.target.id === "switchToCabinetByQrCode") {
            setMethod('OpenCabinetWithQrCode');
        } else if (e.target.id === "switchToCabinetByName") {
            setMethod('OpenCabinetWithName');
            setCabinetName('');
            setScanResult(false);
        }
    }

    const onScanResult = async (result: any, error: any) => {
        const imei = result?.text;
        if (!!result && imei.length) {
            setImeiNumber(imei);
            setScanResult(true);
        }
        if (!!error) {
            console.info(error);
        }
    }

    useEffect(() => {
        if (scanResult) toast.info("L'armoire a été détectée ! Cliquez sur le bouton pour l'ouvrir");
    }, [scanResult]);

    return (
        <div className="workView">
            <div className="cabinetOpening">
                {
                    method?.includes("OpenCabinetWithName") ?
                        <div className="cabinetOpeningByName">
                            <form onSubmit={e => onSubmitCabinetOpening(e)}>
                                <div className="cabinetOpeningOptions">
                                    <input type="text"
                                           className="cabinetNameInput"
                                           placeholder="Entrer le nom de l'armoire..."
                                           onChange={(e) => setCabinetName(e.target.value)}
                                    />
                                </div>
                                <div className="cabinetOpeningButton">
                                    <button className="primary-button"
                                        type="submit">Ouvrir l'armoire</button>
                                </div>
                            </form>
                            <div className="mx-5">
                                <button className="secondary-button"
                                        type="button"
                                        id="switchToCabinetByQrCode"
                                        onClick={e => onClickSwitchScanner(e)} >Passer en mode scanner
                                </button>
                            </div>
                        </div>
                    :
                        <div className="cabinetOpeningByQrCode">
                            <form onSubmit={e => onSubmitCabinetOpening(e)}>
                                <QrReader
                                    constraints={{facingMode: 'environment'}}
                                    scanDelay={500}
                                    onResult={(result: any, error: any) => {
                                        onScanResult(result, error);
                                }}/>
                                {
                                    scanResult &&
                                        <div className="cabinetOpeningButton">
                                            <button className="primary-button"
                                                    type="submit">Ouvrir l'armoire</button>
                                        </div>
                                }
                                <div className="mx-5">
                                    <button className="secondary-button"
                                            type="button"
                                            id="switchToCabinetByName"
                                            onClick={e => onClickSwitchScanner(e)} >Passer en mode saisie
                                    </button>
                                </div>
                            </form>
                        </div>
                }
            </div>
        </div>
    );
}

export default WorkView;
