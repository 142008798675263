import React, {useState} from 'react';
import InstallPwaButton from './InstallPwaButton';

function Footer() {
    const [showInstallButton, setShowInstallButton] = useState<boolean>(true);

    return (<>
        <div className="bottomSpace"></div>
        {
            <div className="installPwaDiv">
                <div className="buttonsGroup installPwaBtn">
                    {showInstallButton &&
                        <InstallPwaButton />
                    }
                </div>
            </div>
        }
    </>);
}

export default Footer;
